// $(document).foundation();

function resizeSwiper() {
    if($(window).height() < 913 && $(window).height() >= 600) {
        if( ($(window).width() - 300) > $('.swiper-slide:first-child img').width() ) {
            $('.swiper-container').css({
                'width': ($(window).height() - 300) * 1.501501501501 + 'px'
            });
        }
        else {
            $('.swiper-container').attr('style', function(i, style) {
                return '';
            });
        }

    }
    else if($(window).height() < 600) {
        $('.swiper-container').css({
            'width': 300 * 1.501501501501 + 'px'
        });
    }
    else {
        $('.swiper-container').attr('style', function(i, style) {
            return '';
        });
    }
}

if($('.swiper-container').length && $(window).width() >= 640) {
    let autoplay = false;
    if($('.swiper-container').hasClass('swiper__home')) {
        autoplay = {
            delay: 2000
        };
    }

    var swiper = new Swiper('.swiper-container', {
        height: 600,
        observer: true,
        navigation: {
            nextEl: '.nav__next',
            prevEl: '.nav__prev'
        },
        autoplay: autoplay
    });

    swiper.on('resize', () => {
        resizeSwiper();
    });

    resizeSwiper();
}

$('#header button').on('click', function() {
    $(this).toggleClass('open');
    $('#nav').toggleClass('open');
});

$('button.more').on('click', function() {
    if($(this).hasClass('open')) {
        $('#info')
            .fadeOut('fast', function() {
                $('#info').hide();
            });
    }
    else {
        $('#info')
            .css('display', 'flex')
            .hide()
            .fadeIn('fast');
    }

    $(this).toggleClass('open');
    $('#slider').toggleClass('info-open');
});

function resizeNav() {
    if($('#slider').length && $(window).width() > 667) {
        let height = $('.swiper-slide:first-child').outerHeight();

        if(height > 310) {
            $('#nav').css({
                'width': height + 'px',
                transform: 'rotate(90deg) translateY(' + (height / 2 - 45) * -1 + 'px)'
            });
        }
    }
}

function resize() {
    // resizeNav();
}

$(window).on('resize', resize);

$(document).ready(function() {
    // resizeNav();
    $('#nav').show();
});
